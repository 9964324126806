
import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';
import { dispatchCheckLoggedIn, dispatchLogInByToken } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';

const startRouteGuard = async (to, from, next) => {
  await dispatchCheckLoggedIn(store);

  if (to.query.token) {
    const loggedIn = readIsLoggedIn(store);
    
    console.log(to.query.token, loggedIn);
    if (to.query.token !== '' && !loggedIn) {
      console.log('logging in');
      // @ts-ignore
      await dispatchLogInByToken(store, {authToken: to.query.token, language: navigator.language.slice(0, 2) });
      if (readIsLoggedIn(store)) {
        const token = `?token=${to.query.token}`;
        window.location.href = to.fullPath.replace(token, '');
      }
    }
  }

  if (readIsLoggedIn(store)) {
    if (to.path === '/' || to.path === '/login' || to.path === '/sign-up') {
      next('/dashboard');
    } else {
      next();
    }
  } else if (readIsLoggedIn(store) === false) {
    if ((to.path as string).startsWith('/dashboard') || to.path === '/') {
      next('/login');
    } else {
      next();
    }
  }
};

@Component
export default class Start extends Vue {
  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public mounted() {
    // this.$root.$i18n.locale = navigator.language.slice(0, 2);
  }
}
